<template>
    <div class="sourceFrom">
        <div class="search" style="display: block">
            <a-tree-select
                    class="search-style"
                    style="width: 300px"
                    v-model="OrgIds"
                    :tree-data="treeData"
                    tree-checkable
                    :maxTagCount="1"
                    placeholder="请选择搜索范围"
                    @change="change"
                    :show-checked-strategy="SHOW_PARENT"
            />
            <!--:show-checked-strategy="SHOW_PARENT"-->
            <a-range-picker
                    class="search-style"
                    style="width: 200px"
                    format="YYYY-MM-DD"
                    :placeholder="['开始时间', '结束时间']"
                    allowClear
                    @change="ChangeSearchTime"
            />
            <a-button class="search-style" @click="_clear">重置</a-button>
            <a-button type="primary" @click="_search">查询</a-button>
            <!--<a-button style="margin-left: 20px" type="primary"  @click="_defaultExpandAllRows">{{defaultExpandAllRows?'全':'全部展开'}}</a-button>-->
        </div>
        <a-table
                :pagination="false"
                v-if="data"
                :columns="columns"
                :data-source="data"
                :scroll="{ y: 500 }"
                :defaultExpandAllRows="defaultExpandAllRows"
        >
            <div slot="key" slot-scope="text, record, index">
                <a-icon
                        style="font-size: 18px;margin-right: 8px;color: #3aa1ff;"
                        @click="_source(text, record)"
                        type="pie-chart"
                />
                <a-icon
                        style="font-size: 18px;color: #8a7bd4;"
                        type="line-chart"
                        @click="_container(text, record)"
                />
            </div>
        </a-table>

        <div v-show="spinning" style="position:fixed;left:50%;">
            <a-drawer
                    :title="visibleName"
                    placement="right"
                    :visible="visible"
                    :width="600"
                    @close="onClose"
            >
                <div id="TopSelling"></div>
            </a-drawer>
            <a-drawer
                    :title="visibleName"
                    placement="right"
                    :visible="visible2"
                    :width="1000"
                    @close="onClose2"
            >
                <div id="container"></div>
            </a-drawer>

            <a-spin style="margin: 150px 0%;">
                <a-icon
                        slot="indicator"
                        type="loading"
                        :spinning="spinning"
                        style="font-size: 44px;color:red;"
                        spin
                />
            </a-spin>
        </div>
    </div>
</template>

<script>
    import G2 from '@antv/g2'
    // import { TreeSelect } from 'ant-design-vue';

    export default {
        name: 'sourceFrom',
        data() {
            return {
                defaultExpandAllRows:true,
                userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                chartData: [
                    {genre: 'Sports', sold: 275},
                    {genre: 'Strategy', sold: 115},
                    {genre: 'Action', sold: 120},
                    {genre: 'Shooter', sold: 350},
                    {genre: 'Fight', sold: 150},
                    {genre: 'Narration', sold: 23},
                    {genre: 'Race', sold: 223},
                    {genre: 'Advanture', sold: 57},
                    {genre: 'Puzzle', sold: 109},
                    {genre: 'Music', sold: 555}
                ],
                columns: [
                    {
                        title: '中心',
                        dataIndex: 'OrgName',
                        key: 'OrgName',
                        align: 'left',
                        width: 220
                    },
                    {
                        title: '开业日期',
                        dataIndex: 'openDate',
                        key: 'openDate',
                        align: 'center',
                        width: '8%'
                    },
                    {
                        title: '总业绩',
                        dataIndex: 'totalAchievement',
                        key: 'totalAchievement',
                        align: 'center',
                        width: '8%'
                    },
                    {
                        title: '续单',
                        dataIndex: 'continueList',
                        key: 'continueList',
                        align: 'center',
                        width: '6%'
                    },
                    {
                        title: '市场',
                        dataIndex: 'market',
                        key: 'market',
                        align: 'center',
                        width: '6%'
                    },
                    {
                        title: '大众点评',
                        dataIndex: 'marketNetwork',
                        key: 'marketNetwork',
                        align: 'center',
                        width: '8%'
                    },
                    {
                        title: '总部网络',
                        dataIndex: 'headquartersNetwork',
                        key: 'headquartersNetwork',
                        align: 'center',
                        scopedSlots: {customRender: 'headquartersNetwork'},
                        width: '8%'
                    },
                    {
                        title: '转介绍',
                        dataIndex: 'introduction',
                        key: 'introduction',
                        align: 'center',
                        width: '8%'
                    },
                    {
                        title: '来访',
                        dataIndex: 'visit',
                        key: 'visit',
                        align: 'center',
                        width: '6%'
                    },
                    {
                        title: '来电',
                        dataIndex: 'call',
                        key: 'call',
                        align: 'center',
                        width: '6%'
                    },
                    {
                        title: '其他',
                        dataIndex: 'newList',
                        key: 'newList',
                        align: 'center',
                        width: '8%'
                    },
                    /*{
                                    title: '思可学院',
                                    dataIndex: 'sikeollege',
                                    key: 'sikeollege',
                                    align: 'center',
                                    width:120

                                },
                                {
                                    title: '其它事业部',
                                    dataIndex: 'otherBusinessDepartments',
                                    key: 'otherBusinessDepartments',
                                    align: 'center',
                                    width:120
                                },*/
                    {
                        title: '操作',
                        dataIndex: 'key',
                        key: 'key',
                        align: 'center',
                        // width:'8%' ,
                        scopedSlots: {customRender: 'key'}
                    }
                ],
                data: '',
                startSignDate: '',
                endSignDate: '',
                spinning: false,
                visibleName: '',
                visible: false,
                visible2: false,
                userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                treeData: [
                    {
                        title: 'Node1',
                        value: '7520669f3b20008c6a6df71e9cebc2f9',
                        key: '3',
                        children: [
                            {
                                title: 'Child Node1',
                                value: '7520669f3b20008c6a6df71e9cebc2f944',
                                key: '4'
                            }
                        ]
                    },
                    {
                        title: 'Node2',
                        value: '6',
                        key: '7',
                        children: [
                            {
                                title: 'Child Node3',
                                value: '7520669f3b20008c6a6df71e9cebc2f9sdfs',
                                key: '8'
                            },
                            {
                                title: 'Child Node4',
                                value: '7520669f3b20008c6a6df71e9cebc2f9wee',
                                key: '9'
                            },
                            {
                                title: 'Child Node5',
                                value: '7520669f3b20008c6a6df71e9cebc2f9weffds',
                                key: '1'
                            }
                        ]
                    }
                ],

                OrgIds: [],
                OrgName: [],
                ParentId: [],
                SHOW_PARENT: 'SHOW_ALL'
            }
        },
        created() {
            this._treeData()
            this.loadData()
        },
        methods: {
            _defaultExpandAllRows(){
                 this.loadData();
                 this.defaultExpandAllRows = !this.defaultExpandAllRows;
                 console.log(this.defaultExpandAllRows);
            },
            _treeData() {
                let self = this
                this.$axios.get(
                    1090,
                    {
                        UserId: self.userinfo.uid
                    },
                    res => {
                        self.treeData = res.data
                        console.log(res)
                    }
                )
            },
            change(value, label, text) {
                if (text.checked) {
                    this.ParentId.push(text.triggerValue)
                } else {
                    if (this.ParentId.length > 0) {
                        for (var i = 0; i < this.ParentId.length; i++) {
                            if (text.triggerValue == this.ParentId[i]) {
                                this.ParentId.splice(i, 1)
                            }
                        }
                    }
                }

                this.OrgName = label
                this.OrgIds = value
            },
            loadData() {
                this.spinning = true
                let self = this
                this.$axios.post(
                    1088,
                    {
                        startSignDate: self.startSignDate,
                        endSignDate: self.endSignDate,
                        OrgIds: JSON.stringify(self.OrgIds),
                        UserId: self.userinfo.uid,
                        OrgName: JSON.stringify(self.OrgName),
                        ParentId: JSON.stringify(self.ParentId)
                    },
                    res => {
                        self.spinning = false
                        console.log(res)
                        // if(res.data.columns){
                        //     self.columns = res.data.columns;
                        // }
                        self.data = res.data.tableData
                    }
                )
            },
            _search() {
                this.loadData()
            },
            _clear() {
                this.OrgIds = []
                this.OrgName = []
                this.startSignDate = ''
                this.endSignDate = ''
                this.ParentId = []
                this.loadData()
            },
            ChangeSearchTime(time, timeString) {
                this.startSignDate = timeString[0]
                this.endSignDate = timeString[1]
            },
            _source(item, record) {
                console.log(record)
                let chardata = [
                    {item: '市场', count: 40, percent: 0.4, key: 'marketDecimal'},
                    {
                        item: '总部网络',
                        count: 21,
                        percent: 0.21,
                        key: 'headquartersNetworkDecimal'
                    },
                    {
                        item: '转介绍',
                        count: 17,
                        percent: 0.17,
                        key: 'introductionDecimal'
                    },
                    {item: '来访', count: 13, percent: 0.13, key: 'visitDecimal'},
                    {item: '来电', count: 9, percent: 0.09, key: 'callDecimal'},
                    {item: '续单', count: 7, percent: 0.07, key: 'continueListDecimal'},
                    {item: '其他', count: 6, percent: 0.06, key: 'newListDecimal'},
                    {
                        item: '大众点评',
                        count: 3,
                        percent: 0.03,
                        key: 'marketNetworkDecimal'
                    }
                ]
                for (var i = 0; i < chardata.length; i++) {
                    chardata[i].count = record[chardata[i].key] * 100
                    chardata[i].percent = record[chardata[i].key]
                }
                console.log(chardata)

                this.visible = true
                this.visibleName = record.OrgName
                setTimeout(function () {
                    document.getElementById('TopSelling').innerHTML = ' '
                    let topSelling = new G2.Chart({
                        container: 'TopSelling',
                        forceFit: true,
                        height: 330,
                        autoFit: true,
                        animate: false
                    })
                    topSelling.legend(false)
                    topSelling.source(chardata, {
                        percent: {
                            formatter: val => {
                                val = Math.ceil((val * 10000) / 1) / 100 + '%'
                                return val
                            }
                        }
                    })
                    topSelling.coord('theta', {
                        radius: 0.8,
                        innerRadius: 0.6
                    })
                    topSelling.tooltip({
                        showTitle: false,
                        itemTpl:
                            '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
                    })
                    let interval = topSelling
                        .intervalStack()
                        .position('percent')
                        .color('item', [
                            '#50affc',
                            '#2377d8',
                            '#62daab',
                            '#134790',
                            '#42c368',
                            '#2f54eb',
                            '#2f34eb',
                            '#4f54eb'
                        ])
                        .label('percent', {
                            formatter: (val, item) => {
                                return `${item.point.item}: ${val}`
                            }
                        })
                        .style({
                            lineWidth: 1,
                            stroke: '#fff'
                        })

                    topSelling.render()
                    interval.setSelected(chardata[0])
                }, 200)
            },
            _container(item, record) {
                this.visibleName = record.OrgName
                this.spinning = true
                let self = this
                let data = ''
                this.$axios.get(
                    1089,
                    {
                        Id: record.Id,
                        startSignDate: self.startSignDate,
                        endSignDate: self.endSignDate
                    },
                    res => {
                        console.log(res)
                        self.spinning = false
                        if (res.data.code == 0) {
                            self.visible2 = true
                            data = res.data.data
                            setTimeout(function () {
                                document.getElementById('container').innerHTML = ' '
                                self.containerTable(data)
                            }, 400)
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            },
            containerTable(value) {
                let data = value
                console.log(data)
                const chart = new G2.Chart({
                    container: 'container',
                    autoFit: true,
                    height: 500,
                    width: 800
                })

                chart.source(data)
                chart.scale({
                    month: {
                        range: [0, 1]
                    },
                    achievement: {
                        nice: true
                    }
                })

                chart.tooltip({
                    showCrosshairs: true,
                    itemTpl:
                        '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
                    // shared: true,
                })

                chart.axis('month', {
                    label: {
                        formatter: val => {
                            return val
                        }
                    }
                })

                chart
                    .line()
                    .position('month*achievement')
                    .color('Fsource')
                    .shape('smooth')

                chart
                    .point()
                    .position('month*temperature')
                    .color('city')
                    .shape('circle')

                chart.render()
            },
            onClose() {
                this.visibleName = ''
                this.visible = false
            },
            onClose2() {
                this.visibleName = ''
                this.visible2 = false
            }
        }
    }
</script>

<style scoped lang="less">
    .sourceFrom {
        background: #ffffff;
        margin-top: 15px;
        min-height: 800px;
        padding: 15px;
    }

    .search {
        margin-bottom: 20px;
        .search-style {
            display: inline-block;
            width: 120px;
            margin-right: 15px;
        }
    }
</style>
